h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}
body {
  /* overflow: hidden; */
  /* overscroll-behavior: contain;  */
}
p {
  margin: 0px;
  padding: 0px;
}
.add_New {
  background: #636ac6;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(99 106 198);
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  height: 45px;
}

.add_New:active {
  background: #1e2585;
}
.add_New button {
  background: #636ac6;
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
  border: 0px;
  color: #ffffff;
}
.add_New button:hover {
  background: #636ac6;
}
.add_New i {
  color: #ffffff;
  margin-right: 5px;
  font-size: 12px;
}
.history_container {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}
.border_Right {
  border-right: 0.5px solid gray;
}

.history_container_background {
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: #e2e2e2;
  padding: 10px 15px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.all_history_details {
  height: calc(100vh - 104px) !important;
  overflow: auto;
}
@media (max-width: 480px) {
  .all_history_container {
    height: calc(100vh - 149px) !important;
    overflow: auto;
  }
}

@media (min-width: 481px) and (max-width: 1800px) {
  .all_history_container {
    height: calc(100vh - 104px) !important;
    overflow: auto;
  }
}

.list_container {
  height: 82vh;
  /* height: 74vh; */
  overflow: auto;
}
.custom-loader.reminder-history-header-loader {
  height: 65vh;
}

.all_history_container .fas {
  color: #636ac6;
}
.all_history_container .far {
  font-size: 12px;
}
/* .all_history_details .fas {
  color: #636ac6;
} */

@media (max-width: 480px) {
  .all_history_details {
    height: calc(100vh - 149px) !important;
    overflow: auto;
  }
}

@media (min-width: 481px) and (max-width: 1400px) {
  .all_history_details {
    height: calc(100vh - 104px) !important;
    overflow: auto;
  }
}

 .all_history_container::-webkit-scrollbar,
.all_history_details::-webkit-scrollbar {
  width: 7px;
}
.all_history_container::-webkit-scrollbar-thumb,
.all_history_details::-webkit-scrollbar-thumb {
  background-color: #ced4da;
}
.all_history_container::-webkit-scrollbar-thumb:hover,
.all_history_details::-webkit-scrollbar-thumb:hover {
  background-color: #ced4da;
}
.all_history_container::-webkit-scrollbar-thumb:active,
.all_history_details::-webkit-scrollbar-thumb:active {
  background-color: #ced4da;
  border: 1px solid #ced4da;
}
.all_history_container::-webkit-scrollbar-track,
.all_history_details::-webkit-scrollbar-track {
  -webkit-box-shadow: 0 0 2px gray inset;
  border-radius: 10px;
} 
.notes_container .col-12 {
  /* border-right: 0.5px solid #f5f5f5; */
  background-color: #f7f7f7;
  box-shadow: 1px 0px 5px #cdcdcd;
}
.notes_container .col-sm-3 .fa.fa-trash {
  color: #b0b0b0;
  font-size: 12px;
}
.history_container_background:hover,
.history_container:hover {
  background-color: #e2e2e2;
  /*font-weight: 600;*/
}
.history_container_background p {
  word-break: break-word;
}
.photo-uploaded {
  width: 100%;
  height: 450px;
  object-fit: contain;
}
.select {
  background-color: gainsboro;
}
.itemCheckbox {
  margin-right: 5px;
}
.sectionContainer {
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  margin:20px 0px
}
.sectionContainer i {

  font-size: 11px;
  color: #636ac6;
  color: white;
  margin-top: 4px;
  margin-left: 0.2px;
}

.sectionContainer i.fas {
  color: #999999;
}
.sectionContainer span {
  cursor: pointer;
}
.sub-container {
  color: #575757;
}
.section-item{
  margin: 10px 0px  !important;
  padding: 10px !important;
  background: #ffffff  !important;
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
  border-radius:5px;
  
}
.section-completed-item{
  margin: 10px 0px  !important;
  padding: 10px !important;
  background: #ffffff  !important;
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
  border-radius:5px;
  display: flex;
}
.custom-loader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 45px) !important;
  font-size: 100px;
  color: #636ac6;
}

.custom-file-loader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40vh) !important;
  font-size: 100px;
  color: #636ac6;
}
.custom-reminder-loader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 122px) !important;
  font-size: 100px;
  color: #636ac6;
}
.custom_no_datafound {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-size: 15px;
}
.custom_no_datafound p {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: white;
  background-color: #636ac6;
  padding: 10px 10px;
}
.custom-delete-loader {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000090;
  z-index: 9;
  color: #fff;
}
/* i{
  width: 30px;
} */
.squareImage {
  object-fit: contain;
  width: 200px;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px #ccc;
}
/*.modal-content {
  width: 675px;
}*/
.react_slick_thumb_slider .slick-slide {
  padding: 0px 10px;
}
.react_slick_thumb_slider .slick-slide > div {
  border: 1px solid rgba(128, 128, 128, 0.1);
  cursor: pointer;
}
.react_slick_main_slider {
  object-fit: contain;
  width: 300px;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px #ccc;
}
.parent_slider {
  margin-bottom: 25px;
}
.main_slider_image {
  object-fit: contain;
  width: 300px;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px #ccc;
  margin: auto;
}

.img-responsive {
  max-width: 100%;
}

.thumb_slider_image {
  object-fit: contain;
  width: 70px;
  height: 70px;
  border-radius: 3px;
  border: solid 1px #ccc;
}
.thumb_slider_image:hover {
  border: 2px solid #636ac6;
}
.img-icon {
  position: relative;
}
.img-action {
  /* position: absolute; */
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  color: #636ac6;
}
.img-action span {
  padding: 1px 2px;
}
button {
  margin: 5px;
}
img.ReactCrop__image {
  object-fit: contain;
  width: 300px;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px #ccc;
  margin: auto;
}
slick-slider.slick-initialized {
  display: flex;
}
ul.photo-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 20px;
}
ul.photo-container li {
  width: calc(33.33% - 10px);
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  margin: 0px 5px;
  background: #f6f6f9;
  margin-bottom: 15px;
  position: relative;
  list-style-type: none;
}
ul.photo-container li img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  justify-content: center;
  margin: 0 auto;
  display: block;
  min-width: 100%;
}
ul.photo-container li span {
  position: absolute;
  top: -8px;
  right: -5px;
  cursor: pointer;
  background: #c6313d;
  color: #fff;
  padding: 0px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.cropper-crop-box {
}
.image_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 5px 0 20px;
  background: #e2e2e2;
  border-radius: 5px;
  padding: 3px;
}
.image_list .img-view {
  list-style-type: none;
  position: relative;
  width: 100%;
}
.video {
  position: relative;
  background: #e2e2e2;
  border-radius: 5px;
  padding: 3px;
}
.video .video-sec {
  width: 100% !important;
}
.image_list .option,

.video .option {
  position: absolute;
  right: 3px;
  top: 5px;
}
.pdf_list .option{
  position: absolute;
  right: 2px;
  top: 3px;
}
.image_list span,
.pdf_list span,
.video span {
  padding: 5px 12px;
  color: #fff;
  cursor: pointer;
}
.image_list .delete,
.pdf_list .delete,
.video .delete {
  background: #ff0000;
}
.image_list .share,
.pdf_list .share,
.video .share {
  background: #636ac6;
  margin-right: 5px;
}
.image_list li img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.pdf_list_container {
  margin: 15px 0px;
  padding: 1px;
  background: #e2e2e2;
  border-radius: 5px;
}

.pdf_list {
  /* border: 1px solid #f0f0f0; */
  background: #f7f7f7;
  /* margin: 20px 0; */
}
.pdf_list li {
  list-style-type: none;
  position: relative;
  width: 100%;
}
.pdf_list li a {
  text-decoration: none;
  color: #1f1f1f;
  font-weight: 400;
}
.pdf_list li a:hover {
  color: #636ac6;
}
.pdf_list li .pdf-icon {
  background-position: center;
  width: 60px;
  height: 75px;
  background-size: cover;
}
.pdf_list li img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 5px;
}
.history-header {
  height: 45px;
  padding: 10px 15px;
  background: #f7f7f7;
  color: #333;
  border-radius: 4px;
}
.custom-check {
  margin: 0px 8px;
}
.custom-check label {
  font-size: 14px;
} 
/* .custom-check input[type=checkbox] {
    visibility: hidden;
} */
.checkBox {
  position: absolute;
  top: 4px;
  left: 10px;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #cccccc;
}

.checkBox:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-check input:active ~ .checkBox {
  border: 1px solid #cccccc;
}
.custom-check input:checked ~ .checkBox {
  background-color: #636ac6;
  border: 1px solid #636ac6;
}
.custom-check input:checked ~ .checkBox:after {
  display: block;
}
.custom-check:hover .custom-check input ~ .checkBox {
  background-color: yellow;
}
.custom-check .checkBox:after {
  left: 4px;
  bottom: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.pl-3 {
  padding-left: 15px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.custom-btn,
.dropdown-toggle {
  color: #636ac6 !important;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #636ac61a;
  border-color: #636ac6 !important;
  box-shadow: none !important;
}
.btn-secondary.custom-btn:focus {
  background: #4048ae2e;
}
.custom-btn-fill {
  color: #ffffff !important;
  border-radius: 4px;
  background-color: #636ac6 !important;
  border-color: #636ac6 !important;
  box-shadow: none !important;
}
.custom-btn-outline {
  color: #fff !important;
  border: 1px solid transparent;
  background-color: transparent !important;
  border-color: #ffffff !important;
  box-shadow: none !important;
}
/*.video-sec{
  width: 100% !important;
  height: auto !important;
}*/
.login-wrap {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 25px 50px;
  background-image: url(../images/login-bg.jpg);
  position: relative;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(242 247 248 /85%);
}
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 100px auto;
  box-shadow: 0 0 10px #f5f5f5;
  position: relative;
  z-index: 9;
}
.login-left {
  width: 50%;
  height: 400px;
  background-image: url(../images/img.jpg);
  background-position: center;
  background-size: cover;
}
.login-right {
  width: 50%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  background-color: #fff;
  font-size: 14px;
}
.login-right input,
.login-right input:focus {
  width: 100% !important;
  box-shadow: none;
  outline: none;
  border-radius: 0;
}
.inputContainer {
  width: 100%;
}
.f-12 {
  font-size: 12px;
}
.inputContainer .react-tel-input .country-list .search {
  display: flex !important;
}
.inputContainer .react-tel-input .country-list .search-emoji {
  border: 1px solid #ccc;
  border-right: 0;
  padding: 4px 5px;
  border-radius: 4px 0px 0 4px;
}
.inputContainer .react-tel-input .country-list .search-box {
  margin-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.dropdown-item {
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
}

.slick-prev,
.slick-next {
  width: 25px;
  height: 25px;
}
.slick-prev:before,
.slick-next:before {
  color: #636ac6;
  font-size: 16px;
}
.disableDelete {
  pointer-events: none;
}

.no-data {
  width: 300px;
  padding: 20px;
  text-align: center;
  margin: auto;
  background: gainsboro;
  margin-top: 200px;
  border-radius: 5px;
}
.resentOtp {
  background: white;
  border: 0;
  text-decoration: underline;
}
.resentOtp:hover {
  color: #636ac6;
}
.info p {
  background-color: gainsboro;
  padding: 20px;
  margin-bottom: 10px;
}
.modal-content {
  width: 96.5%;
}
.search-list {
  background: #fff;
  padding: 0px 10px;
  border: 2px solid #ededed;
  box-shadow: 0 0 5px #ededed;
  margin: 11px 10px;
}
.search-list i {
  color: #999999;
  font-size: 14px;
}
.search-list input,
.search-list input:focus {
  border: 0;
  outline: none;
  box-shadow: none;
  padding-right: 0;
  font-size: 14px;
}
.mobile-toggle {
  display: none;
}
@media (max-width: 767px) and (min-width: 320px) {
  .login-wrap {
    padding: 25px;
  }
  .login-page {
    margin: 50px auto;
    width: 100%;
  }
  .login-left {
    display: none;
  }
  .login-right {
    width: 100%;
    padding: 0 20px;
  }
  .mobile-toggle {
    display: block;
  }
  .mobile-collapse {
    display: none;
  }
  .showmobile-collapse {
    display: block;
    position: absolute;
    top: 105px;
    z-index: 9;
  }
  .main_slider_image {
    width: 100%;
  }
  .mobile_layout {
    display: block !important;
  }
}
.react-player__shadow {
  background: #636ac6;
}
.react-player__shadow {
}
.custom-file-upload {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 20px;
  padding: 0 5px;
  font-size: 14px;
}
/* .custom-file-upload-input{
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    height: 15px;
    padding: 5px;
    font-size: 14px;
    
   
} */
.custom-file-upload input[type="file"] {
  display: none;
}

.show-image {
  position: relative;
  float: left;
  margin: 5px;
}

div.show-image:hover i {
  display: block;
}

div.show-image i {
  position: absolute;
  top: 2px;
  right: 4px;
  display: none;
}
.toast-container_OTP {
  background-color: #636ac6;
  color: white;
}

.history_name_textArea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 69%;
  padding: 0 5px;
  line-height: 1.6;
  font-size: 14px;
  color: #333;
  overflow: auto;
  height: 25px;
}

.memory-name-in-textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  font-weight: 400;
  color: #2b2b2b;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-all;
}

.section_textarea {
  border: 0;
  padding: 0;
  width: 100%;
  background: #f5f5f5;
  font-size: 14px;
  border: none;    
  word-break: break-all;
  white-space: pre-wrap;
  flex: 1 1 0%;
  line-height:20px;
  background-color: transparent;
  resize: none;
  outline: none;
  color: #000000;
  overflow: hidden;
}

.details_item_name {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 100%;
  overflow: hidden;
  padding: 0;
  color: #000000;
  font-weight: 400;
  word-break: break-all;
}

/* upload documnet css */
.files_container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}
.files {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 5px;
}
.files img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  border: 1px solid gray;
}
.files img:hover {
  cursor: pointer;
}
.files i {
  position: absolute;
  right: 2px;
  top: 2px;
  display: none;
}
.files:hover i {
  display: block;
  color: #636ac6;
}
.custom_input {
  position: sticky;
  right: 0;
}
.custom_input label {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #636ac6;
}
.custom_input input[type="file"] {
  display: none;
}
.custom_input i {
  color: white;
}
.filesPreview_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin: auto;
  width: 70%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.ImagefilesPreview img {
  width: 100%;
  max-height: 400px;
  border: 1px solid lightgray;
  object-fit: contain;
}

/* upload img */
.clickImage_container {
  width: 70%;
  margin: auto;
  margin-bottom: 15px;
  margin-top: 15px;
}
.clickImage_container img {
  width: 100%;
  max-height: 400px;
  border: 1px solid lightgray;
  object-fit: contain;
}
.text-truncate {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  /* white-space: normal !important;
  word-break: "break-all"; */
  /* white-space: "pre-wrap"; */
}
.text-truncate-one {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal !important;
}
.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.no_data_found p {
  padding: 10px;
  background: #636ac6;
  color: white;
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 14px;
}
.itemName:first-letter {
  text-transform: capitalize;
}
.text-truncate a {
  color: black;
  text-decoration: none;
}
.text-truncate a:hover {
  color: black;
  text-decoration: none;
}
body.screen_loader {
  pointer-events: none;
}
.navLink_container {
  flex: 1;
  padding: 0 10px;
  word-break: break-word;
}
.navLink_container a {
  text-decoration: none;
  font-size: 14px;
  color: black;
}
.hover_item:hover {
  border: "0.5px solid gray";
}
.memory-name {
  font-size: 18px;
  white-space: pre-wrap;
  word-break: break-all;
  flex:1;
  margin: 0px 10px 0px 0px;
}
.history_name p {
  color: #2b2b2b;
  white-space: pre-line;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-weight: 400;
  width: 100%;
}

.reminder_dropdown {
  position: absolute;
  top: 10;
  right: 0;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  z-index: 1;
}
.reminder_dropdown_icon {
  margin: 5px 10px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}
i {
  cursor: pointer;
}
.remainder_time_dropdown {
  position: absolute;
  min-width: 250px;
  padding: 10px;
  background: white;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.react-datepicker__input-container input {
  border: none;
  outline: none;
  font-size: 14px;
  padding-bottom: 2px;
}

.react-datepicker__input-container input:active {
  border: none;
  outline: none;
}
.react-time-picker {
  width: 100%;

  padding-bottom: 2px;
}
.react-time-picker__wrapper {
  border: none;
  outline: none;
}
.time_font_size_14 {
  font-size: 14px;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
  border: none;
  outline: none;
  font-size: 14px;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
  border: none;
  outline: none;
  font-size: 14px;
}
.react-time-picker__inputGroup__leadingZero {
  font-size: 14px;
}
.react-time-picker__inputGroup__input {
  font-size: 14px;
  border: none;
  outline: none;
}
.save_time_button {
  font-size: 18px;
  background: white;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px 20px;
}
.save_time_button:hover {
  background-color: whitesmoke;
  border-radius: 5px;
}
.disable {
  font-size: 18px;
  background: white;
  border: none;
  outline: none;
  color: gray;
}
.disabled-div {
  pointer-events: none;
  opacity: 0.4;
}
.reminder_drop_down_item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 14px;
}
.reminder_drop_down_item_disable {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 14px;
  pointer-events: none;
  opacity: 0.4;
}
.memory_reminder_time {
  display: flex;
  align-items: center;
  background-color: #d9d7d7;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 30px;
  width: 20%;
}
.memory_reminder_time p {
  margin-left: 3px;
}
.reminder_repeat-container {
  border-bottom: 2px solid gray;
  margin-bottom: 20px;
  padding-bottom: 4px;
  font-size: 14px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.input_type_number {
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  text-align: center;
  width: 30px;
  padding: 0px;
  height: 35px;
  margin: 0px 15px;
}
.custom_repeat_reminder_starts_container {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid gray;
  padding: 15px 0px;
}
.custom_repeat_reminder_starts_right_container {
  width: 30%;
}
.custom_repeat_reminder_starts_left_container {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.custom_repeat_reminder_ends_container {
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid gray;
  padding: 15px 0px;
}
.custom_repeat_reminder_ends_right_container {
  width: 30%;
}
.custom_repeat_reminder_ends_left_container {
}
.custom_repeat_reminder_ends_left_container .item {
  display: flex;
  align-items: center;
}
.custom_repeat_reminder_ends_left_container .item input {
  margin-right: 10px;
}
.circle {
  background: #dbd7d7;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  line-height: 30px;
  margin: 0 5px 3px 0;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.circle:hover {
  /* color: #212529; */
}
.circle_background_change {
  background: #3367d6;
  color: #fff;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  line-height: 30px;
  margin: 0 5px 3px 0;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.items {
  /* white-space: pre-line; */
  color: rgb(0, 0, 0);
  font-weight: 400;
  word-break: break-all;
  white-space: pre-wrap;
}
.sections {
  /* white-space: pre-line; */
  font-size: 15px;
  color: rgb(0, 0, 0);
  font-weight: 700;
  word-break: break-all;
  white-space: pre-wrap;
  padding-top: 0.25px;
  padding-bottom: 0.25px;
}
.custom_reminder_button {
  padding-top: 15px;
  display: flex;
  flex-direction: row-reverse;
}
/* */
.css-1s2u09g-control {
  border: none;
}
.css-1s2u09g-control:hover {
  border-color: white;
}
.reminder_status {
  font-weight: bold;
  padding: 15px 0px;
  font-size: 13px;
  word-break: break-word;
}
.reminder-history-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0px;
  border-bottom: 1px solid #dcdcdc;
  color: #7a5ff5;
}
.reminder_history_container {
  padding: 10px 20px;
}
.reminder_history_month {
  width: 102px;
  height: 28px;
  background: #7a5ff5;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
.reminder_name {
  padding: 0px 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.history_reminder {
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid #d9d9d9;
}
.reminder_name a {
  color: black;
  text-decoration: none;
}
.reminder_name p {
  font-weight: 500;
  font-size: 17px;
  color: #222222;
}
.reminder_time {
  color: #ababab;
  font-size: 12px;
}
.reminder_day {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
  border-radius: 3px;
  padding: 0px 5px;
  width: 45px;
}
.dropdown-menu.show {
  padding: 10px 10px;
  font-size: 16px;
}

#scrollableDiv {
  overflow-y: auto;
  height: calc(100vh - 166px) !important;
}

#scrollableDiv_no_reminder {
  overflow-y: auto;
  height: calc(100vh - 122px) !important;
}

.infinite-scroll-component.data-reduce {
  padding-bottom: 500px;
}
.justify-content-between.notes_container {
  height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: 980px) {
  .memory_reminder_time {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .memory_reminder_time {
    width: 50%;
  }
}
.load_more {
  border: none;
  background: #7a5ff5;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 10px;

  font-weight: bold;
}
.load_more_div {
  display: flex;
  justify-content: flex-end;
  margin: 5px 10px;
}
.add_contact {
  background: white;
  height: 34px;
  border: 1px solid darkgray;
  border-radius: 2px;
  margin: 5px 20px;
}
.contact_item {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #d3d3d3;
}
.item-btn {
  width: "20px";
  font-size: "14px";
  margin-left: "5px";
}
.today_button {
  background: white;
  padding: 5px 5px;
  border-radius: 5px;
  border: none;
}
.add_section_btn {
  color: #636ac6 !important;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #636ac61a !important;
  border-color: #636ac6 !important;
  box-shadow: none !important;
}
.add_section_btn:active {
  background: #545bb21a;
  /* transform: scale(0.98); */
  /* Scaling button to 0.98 to its original size */
  /* box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
  /* Lowering the shadow */
}
/* .delete_item_btn {

}

.delete_item_btn:active{
  text-shadow:3px 6px rgba(255,165,0,.75)
} */
.btn-secondary:hover {
  background: #636ac61a;
}

.btn-secondary.dropdown-toggle:active {
  background: #545bb21a;
}
.btn-secondary.dropdown-toggle {
  background-color: #636ac61a;
}
/* .btn-secondary.dropdown-toggle:active{
  
  background: #545bb21a;
  
} */
button.dropdown-toggle.btn.btn-secondary:active {
  background: #545bb238;
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #545bb21a;
}
.dropdown-menu.show {
  padding: 5px;
}
.open_memory_button a {
  text-decoration: none;
  color: black;
  font-size: 16px;
}
.icon-backgroud {
  background: darkgray;
  border-radius: 3px;
}
.delete_item {
  width: 20px;
  font-size: 9px;
  margin-left: 5px;
  border: 1px solid red;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  line-height: 12px;
  text-align: center;
  background: white;
}
.delete_item:active {
  background: #ff00004d;
}
button.custom-btn.btn.btn-secondary.btn-sm.disabled {
  background: #636ac61a;
}
.sharedContainer .form-control {
  max-width: 230px;
}

.sharedData {
  font-size: 12px;
}
@media (max-width: 480px) {
  .sharedData:hover {
    width: 70%;
    background: #e2e2e2;
    border-radius: 20px;
    cursor: pointer;
  }
}
@media (min-width: 481px) and (max-width: 1400px) {
  .sharedData:hover {
    width: 20%;
    background: #e2e2e2;
    border-radius: 20px;
    cursor: pointer;
  }
}
.TermsAndCondition {
  padding: 20px 30px;
}
.TermsAndCondition div {
  border-bottom: 2px solid #ededed;
}
.TermsAndCondition h3 {
  text-align: center;
  padding: 15px 0px;
  border-bottom: 2px solid #ededed;
}
.TermsAndCondition p {
  padding: 10px 0px;
}
.TermsAndCondition h5 {
  margin-top: 10px;
}
.TermsAndCondition h4 {
  text-align: center;
}
.feedbackForm {
  min-height: 100vh;
  background: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px;
}
.feedbackForm h3 {
  text-align: center;
  margin-bottom: 40px;
}

.feedbackForm form {
  background: white;
  max-width: 500px;
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px 0px;
}
.feedbackForm button {
  background-color: #636ac6 !important;
  width: 100%;
}
.feedbackForm form textarea {
  width: 100%;
  min-height: 90px;
  border: 0px;
  border-bottom: 2px solid #cbc6c6;
}
.feedbackForm form input {
  border: 0px;
  border-bottom: 2px solid #cbc6c6;
  padding: 5px 0px;
  width: 100%;
}
.error {
  font-size: 12px;
  color: red;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.feedbackForm a {
  color: #0d6efd;
  text-decoration: underline;
}
.feedbackForm .bottom {
  text-align: center;
  color: #6a6a6a;
}

.Thankyou {
  min-height: 100vh;
  background: #ededed;
  display: flex;
  align-items: center;

  flex-direction: column;
  padding: 20px 20px;
}
.Thankyou h4 {
  text-align: center;
  color: #6a6a6a;
}

.Thankyou .box {
  background: white;
  max-width: 500px;
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;

  margin: 20px 0px;
}
.caption-container {
  text-align: center;
}
.caption-container textarea {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  width: 70%;
  margin: 10px 0px;
  height: 40.7222px !important;
}
/* .modal-body {
  background: #f7f7f7;
} */
.read_more_text{
  color:#636ac6;
  cursor: pointer;
}
.progress_bar_container{
  margin: 10px 10px;
}
.progress_bar_container p{
  text-align: center;
}
.memeory_icon_container{
  position: relative;
}
.memeory_icon_container .cloud{
  position: absolute;
  top: -7px;
  right: -7px;
}
.upload-icon{
  margin: 0px 5px;
}
.decorate_Completed_Text{
  width: 120px;
  border: 1px solid #636DC3;
  text-align: center;
  font-size: 15px;
  color: #656AC3;
  background: #F2F2FA;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 4px 4px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
}
.completed-item-container{
  margin: 10px 0px;
}
.completed-item-canclebtn{
cursor: pointer;
margin: 0px 0px 0px 8px  ;
}

.item_image-container{
  display: flex;
  margin: 10px 0px ;
  padding: 10px;
  text-align: center;
  border: 1px solid #ededed;
  border-radius: 5px;
 }
.item_image-container .item-image{
flex: 1;
 object-fit: contain;
 max-width: 250px;
 max-height: 200px;
}
 .item_image-container .delete-Item-image-Icon{
  margin-left: 10px;
} 
.custom-border-bottom{
  border-bottom: 2px solid #ededed;
}
.addSectionbtn{
  min-width: 115px;
}
.list-name-container{
  margin: 8px 0px;
}
.play-btn{
  font-size: 30px;
  color: #636AC6;
}
.pause-btn{
  font-size: 30px;
  color: #ff6961;
}
.clock-icon{
  margin-right: 3px;
  font-size: 12px;
}
.share-icon{
  margin-right: 5px;
  color: #636ac6;
}
.delete-section{
  margin-left: 5px;
  /* border: 1px solid red; */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 12px;
  text-align: center;
  background: #636ac6;
}

.delete-section i {
  font-size: 11px;
  color: white;
  margin-top: 4px;
  margin-left: 0.2px;
}
.section-name-in-textarea{
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  color: #2b2b2b;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-all;
}
.section-name{
  font-size: 15px;
  font-weight: 700;
  white-space: pre-wrap;
  word-break: break-all;
  flex:1;
  margin: 0px 10px 0px 0px;
  padding-top: 0.25px;
  padding-bottom: 0.25px;
}
.admin-container{
  display: flex;
}
*::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
}
.open-app{
  display: flex;
  height: 60px;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  background-color: #ffffff;
}
.open-app p{
  font-size: 14px;
  font-weight: 700;
}
.open-app i{
  font-size: 23px;
  color: #636ac6;
}
.open-app button{
  color: #636ac6;
  border: 1px solid #636ac6;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
}
.open-app-text{
  flex: 1;
  margin:0px 5px
}
.upload-icon{
  display: flex;
  padding: 3px;
}
.upload-icon input{
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 19px;
  opacity: 0; 
  width: 15px;
  left: 20px;
  position: relative;
}
