.admin-header {
  height: 60px;
  padding: 10px 15px;
  background: #f7f7f7;
  color: #333;
  border-radius: 4px;
  box-shadow: rgb(229 229 229) 0px 2px 5px;
  display: flex;
  align-items: center;
  /* justify-content: center;    */
}
.admin-header img {
  width: 180px;
}
.admin-sidebar {
  background: #636ac6;
  height: calc(100vh - 60px);
  padding: 10px 0px;
}
.sidebar-item {
  padding: 20px;
  border-bottom: 1px solid white;
  display: flex;
}
.sidebar-item i {
  margin: 0px 10px;
  font-size: 20px;
}

.admin-sidebar a {
  color: white;
  font-weight: 700;
  text-decoration: none; /* no underline */
}
.sidebar-item i {
  color: white;
}
.admin-detail-page {
  height: calc(100vh - 60px);
  padding: 20px;
}
.admin-notification-detail-page-container {
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 5px;
}
.admin-notification-detail-page-container textarea {
  width: 100%;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  padding: 10px;
  height: 118px !important;
}
.send-notification-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.send-notification-btn button {
  font-weight: 700;
  color: #ffffff !important;
  border-radius: 4px;
  background-color: #636ac6 !important;
  border-color: #636ac6 !important;
  box-shadow: none !important;
}
.select-user-table table tbody {
  border-top: 1px solid currentColor !important;
}

.select-user-table {
  padding: 20px 0px 0px 0px;
}
.select-user-table table tr td {
  border: 1px solid rgb(237, 237, 237);
}
.select-user-table table tr {
  border-width: 0;
}
.select-user-table table thead {
  position: sticky;
  top: 0;
  background: #f7f7f7;
}
.table-responsive {
   overflow-x: scroll;
   height: calc(100vh - 327px) !important;
  /* height: 200px; */
}
.delete-user i {
  color: #636ac6;
}
.admin-logo {
  margin: auto;
}
